@media all {

    .login {
        margin: 10px 0;
        min-width: 320px;
        padding: 35px;
        background: white;
        border-radius: 3px;
    }

    .v-center {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .logo {
        margin-left: auto;
        margin-right: auto;
        margin-top: 10%;
        max-width: 220px;
    }
}